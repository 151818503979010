<template>
  <v-card class="card-shadow border" color="white">
    <v-card v-if="isAccountTransaction" class="d-flex justify-end py-1"
            style="display: flex; justify-content: flex-end;" flat tile>
      <v-btn class="ma-2" outlined color="dark" small @click.prevent="dialogForDownload = true">
        <v-icon>mdi-download-outline</v-icon>
      </v-btn>
    </v-card>
    <v-row align="center" class="pa-8" style="gap: 20px">
      <v-text-field v-model="dateRangeText" label="Date range" prepend-icon="mdi-calendar"
                    @click:prepend="dialog = !dialog" @click="dialog = !dialog" readonly />
      <v-btn color="primary" @click.prevent="Business(search)">
        Search
      </v-btn>
      <v-btn color="secondary" @click.prevent="dates = []; Business()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-divider vertical />
      <v-text-field v-model="search" :append-icon="search ? 'mdi-close' : 'mdi-magnify'" label="Search"
                    @keydown.enter="searchData" @click:append="clearSearch" />
      <v-btn color="primary" @click.prevent="searchData">
        Search
      </v-btn>
    </v-row>
    <v-tabs v-model="selectedTab" @change="handleTabChange">
      <v-tab v-for="(tab, index) in tabs" :key="index">
        {{ tab.label }}
      </v-tab>
    </v-tabs>

    <v-data-table :headers="headers" :items="data" hide-default-footer :loading="loading" :loading-text="message"
                  :items-per-page="currentRows">
      <template v-slot:item.amount="{ item }">
        <v-title text
                 :class="{ 'success-text-btn': parseInt(item.amount) > 0, 'error-text-btn': parseInt(item.amount) <= 0 }">
          {{ item.amount ? formatCurrency(parseInt(item.amount)) : "0" }}
        </v-title>
      </template>
      <template v-slot:item.created_at="{ item }">
        <v-title text>
          {{ formatDate(item.created_at) }}
        </v-title>
      </template>
      <template v-slot:item.finxact_party_account_num="{ item }">
        <v-title text style="display: flex; gap: 4px; align-items: center;">
          {{ item.finxact_party_account_num ? "xxxx-xxxx-" + item.finxact_party_account_num : '' }}
          <v-btn @click.stop="transaction_id = item.id; accountNoDialog = true;" icon small color="primary"
                 v-if="item.finxact_party_account_num">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
        </v-title>
      </template>
      <template v-slot:item.status="{ item }">
        <v-btn text small
               :class="{ 'success-sec-btn': item.status === 'COMPLETION', 'error-sec-btn': item.status === 'DECLINED', 'warning-sec-btn': item.status === 'PENDING', 'clear-sec-btn': item.status === 'CLEARED' }"
               class="default-btn">
          {{ item.status }}
        </v-btn>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn text small color="primary" @click.prevent="showFullDetails = item; dialogFull = true;" hide-details>
          <v-icon>{{ item.action }}</v-icon>
        </v-btn>
      </template>
      <template v-slot:body.append>
        <tr>
          <td colspan="2" />
          <td>
            <v-select :items="tnxTypes" v-model="currentTnx" @change="handleChangeTnxType" />
          </td>
          <td colspan="5" />
        </tr>
      </template>
    </v-data-table>
    <v-row align="center" justify="end">
      <v-col cols="auto">
        <v-select style="width: 60px;" :items="rowsPerPage" @change="handleChangeRows" v-model="currentRows" />
      </v-col>
      <v-col cols="auto" style="min-width: 400px;">
        <v-pagination @input="pageChange" color="black" v-model="currentPage" :length="noOfPages"
                      :total-visible="10" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-date-picker v-model="dates" range :max="today" />
          <v-card-actions>
            <v-spacer />
            <v-btn color="error" text @click="dialog = false; dates = [];">
              Clear
            </v-btn>
            <v-btn color="primary" text @click="dialog = false;">
              Done
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogForDownload" persistent max-width="290">
        <v-card>
          <v-date-picker v-model="downloadDates" range multiple :max="today" />
          <v-card-actions>
            <v-spacer />
            <v-btn v-if="downloadDates.length > 1" :loading="downloadBtnLoading" :disabled="downloadBtnLoading"
                   color="primary" text @click="downloadCsv()">
              Download
            </v-btn>
            <v-btn color="error" text @click="dialogForDownload = false; downloadDates = [];">
              Clear
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogFull" color="white" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card style="background-color: #eeeeee;">
          <v-toolbar dark color="secondary">
            <v-container>
              <v-row class="align-center justify-space-between">
                <v-col cols="auto">
                  <v-toolbar-title>Payment Details</v-toolbar-title>
                </v-col>
                <v-col cols="auto">
                  <v-btn icon dark @click="dialogFull = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-toolbar>
          <v-container class="py-0" v-if="showFullDetails">
            <v-list three-line subheader>
              <v-row class="justify-space-between align-center pa-6">
                <v-col cols="auto">
                  <v-row class="align-center">
                    <v-col cols="auto" class="text-h5">
                      Payment information
                    </v-col>
                    <v-col cols="auto"
                           :class="{ 'success-sec-btn': showFullDetails.status === 'COMPLETION', 'error-sec-btn': showFullDetails.status === 'DECLINED', 'warning-sec-btn': showFullDetails.status === 'PENDING' }">
                      {{
                        showFullDetails.status }}
                    </v-col>
                  </v-row>
                  <v-col cols="auto" class="text-h6" v-if="showFullDetails.status === 'DECLINED'">
                    Reason :
                    {{ showFullDetails.gateway_declined_reason !== null ? showFullDetails.gateway_declined_reason : '-'
                    }}
                  </v-col>
                </v-col>
                <!-- <v-col cols="auto">
                    <v-btn outlined color="dark">Download</v-btn>
                  </v-col> -->
              </v-row>
              <div style="background-color: #eeeeee;" class="px-6 py-2">
                <span class="grey--text">ID: </span>
                <span class="font-weight-medium"> {{ showFullDetails.finxact_order_id }}</span>
              </div>
              <v-row class="pa-6">
                <v-col cols="12" md="6" class="pa-6">
                  <v-card>
                    <v-row style="gap: 20px">
                      <div class="text-h6 grey--text">
                        Title :
                      </div>
                      <div class="font-weight-medium text-h6">
                        {{ showFullDetails.title }}
                      </div>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" class="pa-6">
                  <v-card>
                    <v-row style="gap: 20px">
                      <div class="text-h6 grey--text">
                        Transfer Type :
                      </div>
                      <div class="font-weight-medium text-h6">
                        {{ showFullDetails.txn_type }}
                      </div>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" class="pa-6">
                  <v-card>
                    <v-row style="gap: 20px">
                      <div class="text-h6 grey--text">
                        Amount :
                      </div>
                      <div class="font-weight-medium text-h6"
                           :class="{ 'success-text-btn': parseInt(showFullDetails.amount) > 0, 'error-text-btn': parseInt(showFullDetails.amount) <= 0 }">
                        $ {{ showFullDetails.amount }}
                      </div>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" class="pa-6">
                  <v-card>
                    <v-row style="gap: 20px">
                      <div class="text-h6 grey--text">
                        Transaction Date :
                      </div>
                      <div class="font-weight-medium text-h6">
                        {{ formatDate(showFullDetails.created_at) }}
                      </div>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" class="pa-6">
                  <v-card>
                    <v-row style="gap: 20px">
                      <div class="text-h6 grey--text">
                        Business Name :
                      </div>
                      <div class="font-weight-medium text-h6">
                        {{ showFullDetails.legal_name }}
                      </div>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" class="pa-6">
                  <v-card>
                    <v-row style="gap: 20px">
                      <div class="text-h6 grey--text">
                        Account No :
                      </div>
                      <div class="font-weight-medium text-h6">
                        <span
                          v-if="showFullDetails.finxact_party_account_num">xxxx-xxxx-</span>{{
                          showFullDetails.finxact_party_account_num }}
                      </div>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-divider />
                </v-col>
                <v-col cols="12" md="6" class="pa-6">
                  <v-card>
                    <v-row style="gap: 20px">
                      <div class="text-h6 grey--text">
                        Receiver Account No. :
                      </div>
                      <div class="font-weight-medium text-h6">
                        {{ showFullDetails.ach_account_number }}
                      </div>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" class="pa-6">
                  <v-card>
                    <v-row style="gap: 20px">
                      <div class="text-h6 grey--text">
                        Receiver Account Type :
                      </div>
                      <div class="font-weight-medium text-h6">
                        {{ showFullDetails.ach_account_type }}
                      </div>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" class="pa-6">
                  <v-card>
                    <v-row style="gap: 20px">
                      <div class="text-h6 grey--text">
                        Receiver Bank Name :
                      </div>
                      <div class="font-weight-medium text-h6">
                        {{ showFullDetails.ach_bank_name }}
                      </div>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" class="pa-6">
                  <v-card>
                    <v-row style="gap: 20px">
                      <div class="text-h6 grey--text">
                        Receiver Routing No. :
                      </div>
                      <div class="font-weight-medium text-h6">
                        {{ showFullDetails.ach_routing_number }}
                      </div>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" class="pa-6"
                       v-if="showFullDetails.txn_type == 'ACHincoming' || showFullDetails.txn_type == 'ACHoutgoing'">
                  <v-card>
                    <v-row style="gap: 20px">
                      <div class="text-h6 grey--text">
                        Description :
                      </div>
                      <div class="font-weight-medium text-h6">
                        {{ showFullDetails.description }}
                      </div>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12" v-if="showFullDetails.txn_type === 'card'">
                  <v-row>
                    <v-col cols="12">
                      <v-divider />
                    </v-col>
                    <v-col cols="12" md="6" class="pa-6">
                      <v-card>
                        <v-row style="gap: 10px 20px">
                          <div class="text-h6 grey--text">
                            Marqeta Transaction Token :
                          </div>
                          <div class="font-weight-medium text-h6">
                            {{ showFullDetails.marqeta_transaction_token }}
                          </div>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="6" class="pa-6">
                      <v-card>
                        <v-row style="gap: 20px">
                          <div class="text-h6 grey--text">
                            Acceptor Mid :
                          </div>
                          <div class="font-weight-medium text-h6">
                            {{ showFullDetails.acceptor_mid }}
                          </div>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="6" class="pa-6">
                      <v-card>
                        <v-row style="gap: 20px">
                          <div class="text-h6 grey--text">
                            Acceptor Name :
                          </div>
                          <div class="font-weight-medium text-h6">
                            {{ showFullDetails.acceptor_name }}
                          </div>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="6" class="pa-6">
                      <v-card>
                        <v-row style="gap: 20px">
                          <div class="text-h6 grey--text">
                            Txn Category :
                          </div>
                          <div class="font-weight-medium text-h6">
                            {{ showFullDetails.txn_category }}
                          </div>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="6" class="pa-6">
                      <v-card>
                        <v-row style="gap: 20px">
                          <div class="text-h6 grey--text">
                            Network :
                          </div>
                          <div class="font-weight-medium text-h6">
                            {{ showFullDetails.network_reference_id }}
                          </div>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="6" class="pa-6">
                      <v-card>
                        <v-row style="gap: 20px">
                          <div class="text-h6 grey--text">
                            Finxact Thread Id :
                          </div>
                          <div class="font-weight-medium text-h6">
                            {{ showFullDetails.finxact_thread_id }}
                          </div>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="6" class="pa-6">
                      <v-card>
                        <v-row style="gap: 20px">
                          <div class="text-h6 grey--text">
                            Gateway Declined Reason :
                          </div>
                          <div class="font-weight-medium text-h6">
                            {{ showFullDetails.gateway_declined_reason }}
                          </div>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="6" class="pa-6">
                      <v-card>
                        <v-row style="gap: 20px">
                          <div class="text-h6 grey--text">
                            Pan Entry Mode :
                          </div>
                          <div class="font-weight-medium text-h6">
                            {{ showFullDetails.pan_entry_mode }}
                          </div>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-list>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="accountNoDialog" persistent max-width="350">
        <v-card>
          <v-container>
            <v-card-title class="text-h5">
              Full Account No.
            </v-card-title>
            <div class="pa-4 pt-0">
              <v-form ref="form" v-model="valid">
                <v-text-field v-model="code" :rules="codeRules" label="code*" required />
                <v-chip color="primary" label outlined v-if="accountNo">
                  {{ accountNo }}
                  <v-btn icon small color="primary" @click.prevent="copyToClipboard()">
                    <v-icon right>
                      {{ copyIcon }}
                    </v-icon>
                  </v-btn>
                </v-chip>
                <div class="mt-4 d-flex">
                  <v-spacer />
                  <v-btn :disabled="!valid" class="mr-2" color="success" @click="fullAccountNoFun(transaction_id)">
                    Validate
                  </v-btn>
                  <v-btn text color="error"
                         @click="accountNoDialog = false; code = ''; accountNo = ''; $refs.form.resetValidation()">
                    close
                  </v-btn>
                </div>
              </v-form>
            </div>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
    <v-snackbar :timeout="2000" :value="errorBar" absolute centered right tile color="red accent-2">
      {{ errorMessage }}
    </v-snackbar>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      code: '',
      codeRules: [
        v => !!v || 'Code is required',
      ],
      accountNo: '',
      transaction_id: '',
      copyIcon: 'mdi-clipboard-text-outline',

      headers: [
        { text: 'Amount', value: 'amount' },
        { text: 'Status', value: 'status' },
        { text: 'Transfer Type', value: 'txn_type' },
        { text: 'Account No.', value: 'finxact_party_account_num' },
        { text: 'Label', value: 'label' },
        { text: 'Business Name', value: 'legal_name' },
        { text: 'Acceptor Name', value: 'acceptor_name'},
        // { text: 'Platform', value: 'payment_from' },
        { text: 'Transaction Date', value: 'created_at' },
        // { text: 'Created Date', value: 'created_date' },
        { text: 'Actions', value: 'action' },
      ],
      data: [],
      totalData: 0,
      dialog: false,
      accountNoDialog: false,
      showFullDetails: null,
      downloadDates: [],
      today: new Date().toISOString(), // Get the current date
      dialogForDownload: false,
      all_transactions_count: [],

      dialogFull: false,
      fullData: null,
      errorBar: false,
      downloadBtnLoading: false,
      errorMessage: '',
      isAccountTransaction: false,

      currentPage: 1,
      // noOfPages: 1,

      rowsPerPage: [10, 15, 30, 50, 100],
      currentRows: 10,

      tnxTypes: ['ALL', 'ACH Incoming', 'ACH Outgoing', 'Core', 'Card', 'Card Refund'],
      mappedValues: {
        'ACH Incoming': 'ACHincoming',
        'ACH Outgoing': 'ACHoutgoing',
        'Core': 'CORE',
        'Card': 'card',
        'Card Refund': 'cardRefund'
      },
      currentTnx: 'ALL',

      dates: [],

      message: 'Loading... Please wait',
      loading: true,

      tabs: [
        {
          label: 'All',
          businessData: [],
        },
        {
          label: 'Completion',
          businessData: [],
        },
        {
          label: 'Pending',
          businessData: [],
        },
        {
          label: 'Declined',
          businessData: [],
        },
        {
          label: 'Refund',
          businessData: []
        },
        {
          label: 'Cleared',
          businessData: []
        },
      ],
      selectedTab: 0,
      search: '',

      abortController: new AbortController()
    };
  },
  props: {
    currentServer: Object,
  },
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ')
    },
    noOfPages() {
      return Math.ceil(parseInt(this.totalData) / this.currentRows)
    },
  },
  methods: {
    clearSearch() {
      this.search = '';
    },
    handleTabChange() {
      this.currentPage = 1
      this.Business(this.search);
    },

    formatCurrency(amount) {
      // Convert the amount to a number and use toLocaleString to format as currency
      return Number(amount).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      });
    },
    tabName(tab) {
      switch (tab) {
        case 0:
          return ''
        case 1:
          return '&status=COMPLETION'
        case 2:
          return '&status=PENDING'
        case 3:
          return '&status=DECLINED'
        case 5:
          return '&status=CLEARED'
        default:
          break;
      }
    },
    filterByDate() {
      this.Business();
    },
    searchData() {
      this.currentPage = 1;
      this.Business(this.search)
    },
    getDateQuery(dates) {
      // Dates array
      const datesArray = dates;

      // Convert the date strings to Date objects
      const date1 = new Date(datesArray[0]);
      const date2 = new Date(datesArray[1]);

      // Find the older and newer dates
      const oldDate = date1 < date2 ? date1 : date2;
      const newDate = date1 < date2 ? date2 : date1;

      // Format the dates as YYYY-MM-DD strings
      const fromDate = oldDate.toISOString().split('T')[0];
      const toDate = newDate.toISOString().split('T')[0];

      // Create the final string
      const queryString = `from=${fromDate}&to=${toDate}`;
      return queryString
    },
    convertToUTC(date) {
      const utcDate = new Date(date);
      const utc = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
      return utc.toISOString();
    },

    // handleTabChange(selectedTabIndex) {
    //     this.selectedTab = selectedTabIndex;
    //     if (this.tnxTypes[selectedTabIndex] === 'Card-Refund') {
    //       this.handleRefundTabChange();
    //     } else {
    //       this.Business(this.search);
    //     }
    //   },

    //   handleRefundTabChange() {
    //     this.currentPage = 1;
    //     const v_txn_category = '&txn_category=refund';
    //     let queryString = this.search + v_txn_category;
    //     this.Business(queryString);
    //   },

    handleDateRangeSelection(dateRange) {
      // Convert start and end dates from EST to UTC format
      const utcStartDate = this.convertToUTC(dateRange[0]);
      const utcEndDate = this.convertToUTC(dateRange[1]);

      console.log('UTC Start Date:', utcStartDate);
      console.log('UTC End Date:', utcEndDate);


      // Call the Business method with the selected date range
      const queryString = this.getDateQuery([utcStartDate, utcEndDate]);
      this.Business('', queryString);
    },
    async Business(searchString, queryString = '') {
      try {
        this.abortController.abort(); // Cancel previous requests
        this.abortController = new AbortController(); // Create new AbortController
        const signal = this.abortController.signal;

        this.loading = true
        this.message = 'Loading... Please wait'
        const v_page = '?page=' + this.currentPage;
        const v_per_page = '&per_page=' + this.currentRows;
        var v_tnx_type = ''
        if (this.currentTnx != 'ALL') {
          v_tnx_type = '&txn_type=' + this.mappedValues[this.currentTnx];
        }
        const v_status = this.tabName(this.selectedTab);
        const SearchString = searchString ? '&search_string=' + searchString : '';
        const QueryDate = this.dates.length > 1 ? ('&' + this.getDateQuery(this.dates)) : '';

        const queryFor = this.$route.query.for;
        var idQuery = ''
        if (queryFor === 'account') {
          idQuery = `&account_id=${this.$route.params.id}`
        } else if (queryFor === 'contact') {
          idQuery = `&contact_id=${this.$route.params.id}`
        } else if (queryFor === 'card') {
          idQuery = `&card_id=${this.$route.params.id}`
        } else {
          idQuery = ''
        }


        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl
        const baseurl = process.env.VUE_APP_API_BASE_URL
        var myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
        const response = await fetch(`${baseurl}admin/transactions${v_page + v_per_page + v_tnx_type + v_status + SearchString + QueryDate + idQuery}${queryString}`, {
          'Content-Type': 'application/json',
          method: 'GET',
          redirect: 'follow',
          headers: myHeaders,
          signal,
        });
        if (!response.ok) {
          if (response.status == '401') {
            throw new Error('Unauthorized');
          }
        }
        const data = await response.json();

        let transactionById = data?.data;

        const TransactionData = transactionById?.map((item) => {
          return {
            ...item.attributes,
            id: item?.id,
            created_date: this.formatDate(new Date(item?.attributes?.created_at)),
            action: 'mdi-eye'
          };
        });
        this.data = TransactionData;
        this.totalData = parseInt(data?.all_transactions_count)
        this.loading = false
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.name === 'AbortError') {
          // Request was cancelled
          this.loading = true
          this.message = 'Loading... Please wait'
        } else if (error.message.toUpperCase() === 'Unauthorized'.toUpperCase()) {
          this.$router.push('/admin-login');
        } else {
          this.loading = false
          this.message = 'something went wrong'
        }
      }
    },
    formatDate(inputDate) {
      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];

      const date = new Date(inputDate);
      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';

      const formattedDate = `${month} ${day}, ${year}, ${hours % 12}:${minutes.toString().padStart(2, '0')} ${ampm}`;

      return formattedDate;
    },
    pageChange() {
      this.Business(this.search);
    },
    handleChangeRows() {
      this.currentPage = 1
      this.Business(this.search);
    },
    handleChangeTnxType() {
      this.currentPage = 1
      this.Business(this.search);
    },

    async downloadCsv() {
      // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl;
      const baseurl = process.env.VUE_APP_API_BASE_URL
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);

      const raw = JSON.stringify({
        'account_id': this.$route.params.id
      });

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      const QueryDate = this.downloadDates.length > 1 ? `?${this.getDateQuery(this.downloadDates)}` : '';
      try {
        const response = await fetch(baseurl + 'admin/transactions/download_transaction_history_csv' + QueryDate, requestOptions);
        if (!response.ok) {
          if (response.status == '401') {
            throw new Error('Unauthorized');
          } else {
            throw new Error('Network response was not ok');
          }
        }
        const data = await response.json();

        // Handle successful login here
        const link = document.createElement('a');
        link.href = data?.transaction_url;
        link.download = 'data.csv'; // Specify the filename for the downloaded file
        link.target = '_blank'
        // Simulate a click on the anchor element to trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up the anchor element
        document.body.removeChild(link);
        this.downloadBtnLoading = false;
        this.dialogForDownload = false;
        this.downloadDates = []
      } catch (error) {
        this.downloadBtnLoading = false;
        this.dialogForDownload = false;
        this.downloadDates = []
        console.error('Error fetching data:', error);
        if (error.message.toUpperCase() === 'Unauthorized'.toUpperCase()) {
          this.$router.push('/admin-login');
        } else {
          this.errorBar = true
          this.errorMessage = error.message
        }
      }
    },
    async fullAccountNoFun(id) {
      if (this.valid) {
        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl;
        const baseurl = process.env.VUE_APP_API_BASE_URL
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);

        const requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        try {
          const response = await fetch(baseurl + `admin/transactions/${id}/mask_account_number?code=${this.code}`, requestOptions);
          if (!response.ok) {
            if (response.status == '401') {
              throw new Error('Unauthorized');
            } else {
              throw new Error('Network response was not ok');
            }
          }
          const data = await response.json();
          this.accountNo = data?.masked_finxact_party_account_num
        } catch (error) {
          console.error('Error fetching data:', error);
          if (error.message.toUpperCase() === 'Unauthorized'.toUpperCase()) {
            this.$router.push('/admin-login');
          } else {
            this.errorBar = true
            this.errorMessage = error.message
          }
        }
      }
    },
    copyToClipboard() {
      const element = this.accountNo;
      const cb = navigator.clipboard;
      cb.writeText(element).then(() => this.copyIcon = 'mdi-check');
      setTimeout(() => {
        this.copyIcon = 'mdi-clipboard-text-outline'
      }, 3000);
      // You can also provide user feedback here, e.g., show a message that text has been copied.
    }
  },
  mounted() {
    this.isAccountTransaction = this.$route.query.for === 'account' ? true : false;
    this.Business();
  },
  watch: {
    $route() {
      this.isAccountTransaction = this.$route.query.for === 'account' ? true : false;
      this.message = 'Loading... Please wait'
      this.loading = true
      this.search = ''
      this.Business()
    },
  },
};
</script>

<style>
.v-slide-group__content .v-tab:nth-child(6) {
  display: none;
}
</style>